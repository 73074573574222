@import '~app/assets/styles/media.less';

.frame {
  flex: 1;
  width: 100%;

  display: flex;
  justify-content: space-between;

  @media (--tablet-and-less-media) {
    & {
      flex-direction: column;
      justify-content: flex-start;
    }
  }
}

.pane {
  flex: 1;
  margin: 0.5rem;

  display: flex;
  flex-direction: column;

  @media (--tablet-and-less-media) {
    & {
      flex: 0;
      margin: 0.5rem 0;
    }
  }

  & > div:not(:last-child) {
    margin-bottom: 1rem;
  }

  @media (--tablet-and-less-media) {
    & > div:last-child {
      margin-bottom: 1rem;
    }
  }
}

.objectives {
  flex-basis: 50%;
}

.no-objectives {
  @media (--tablet-and-less-media) {
    & {
      display: none;
    }
  }
}

.team {
  flex-basis: 50%;

  @media (--tablet-and-less-media) {
    & {
      flex-basis: unset;
    }
  }
}

.no-team {
  @media (--tablet-and-less-media) {
    & {
      display: none;
    }
  }
}

.briefing {
  flex-basis: 70%;
  overflow: hidden;

  @media (--tablet-and-less-media) {
    & {
      flex-basis: 100%;
    }
  }

  .skeleton > div {
    margin-bottom: 0.25rem;
  }
}

.no-briefing {
  @media (--tablet-and-less-media) {
    & {
      display: none;
    }
  }
}

.procedures {
  flex: 1;

  @media (--tablet-and-less-media) {
    & {
      flex: 0;
    }
  }

  .procedure {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .title {
      text-align: left;
    }
    .score {
      text-align: right;
    }
  }
}
