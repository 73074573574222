@import '~app/assets/styles/media.less';

.container {
  flex: 1;

  .title {
    margin: 0.5rem 0 1rem 0;
    box-sizing: border-box;
  }

  .content {
    margin: 1rem 0 0 0; // lineup with the huge title
    display: flex;
    flex-wrap: wrap;
    row-gap: 0.75rem;
    column-gap: 0.75rem;

    @media (--mobile-media) {
      & {
        flex-direction: column;
        column-gap: 0;
      }
    }
  }
}

.summary {
  width: 40%;
  display: flex;
  flex-direction: row;

  & > div {
    white-space: nowrap;
  }

  @media (--mobile-media) {
    & {
      width: 100%;
      flex-direction: column;
    }

    .comma {
      display: none;
    }
  }
}

.highlight-content {
  flex-basis: calc(33.3333% - (0.75rem * 2 / 3));

  @media (--tablet-media) {
    & {
      flex-basis: calc(50% - (0.75rem / 2));
    }
  }
}

.highlight {
  .card {
    display: flex;

    .detail {
      flex: 1;
      margin-left: 0.35rem;

      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    .description {
    }
  }

  .skeleton-title {
    margin-bottom: 0.75rem;
  }
}
