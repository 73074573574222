@import (reference) '~app/assets/styles/variables.less';

.container {
  flex: 1;
  width: 100%;

  display: flex;
}

.frame {
  flex: 1;
  width: 100%;

  background-repeat: no-repeat;
  background-size: cover;
  background-color: #080808;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.logo-container {
  width: 100%;
  height: 2.75rem;
  margin-bottom: 0.25rem;
}

.menu {
  margin: 1rem 0 0.5rem 1rem;

  li {
    list-style: none;
  }

  .heading {
    font-family: 'Bank Gothic Bold', sans-serif;
    text-transform: uppercase;
    font-size: 1.7rem;
    line-height: 0.9em; // not rem, depends on font size
    letter-spacing: 0.05rem;
    text-decoration: none;
    color: #fff;

    &:global(.active),
    &:hover {
      color: @swat-yellow;
    }
  }

  .divider {
    font-family: 'Airborne', sans-serif;
    font-size: 0.6rem;
    letter-spacing: 0.05rem;
    width: 70%;
    margin-top: 0.25rem;
    margin-bottom: 0.05rem;
    border-top: solid 0.15rem #666;
    color: #888;
  }
}
