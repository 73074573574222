@import (reference) '~app/assets/styles/variables.less';

.name {
  &:global(.-swat) {
    color: @swat-blue;
  }
  &:global(.-suspects) {
    color: @swat-red;
  }
}
