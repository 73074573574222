@font-face {
  font-family: 'Play';
  src: url('../fonts/play_regular-webfont.eot');
  src:
    url('../fonts/play_regular-webfont.eot?#iefix') format('embedded-opentype'),
    url('../fonts/play_regular-webfont.woff') format('woff'),
    url('../fonts/play_regular-webfont.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Airborne';
  src: url('../fonts/airborne-webfont.eot');
  src:
    url('../fonts/airborne-webfont.eot?#iefix') format('embedded-opentype'),
    url('../fonts/airborne-webfont.woff') format('woff'),
    url('../fonts/airborne-webfont.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Bank Gothic Bold';
  src: url('../fonts/bank_gothic_bold-webfont.eot');
  src:
    url('../fonts/bank_gothic_bold-webfont.eot?#iefix') format('embedded-opentype'),
    url('../fonts/bank_gothic_bold-webfont.woff') format('woff'),
    url('../fonts/bank_gothic_bold-webfont.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'DS-Digital';
  src: url('../fonts/ds_digit-webfont.eot');
  src:
    url('../fonts/ds_digit-webfont.eot?#iefix') format('embedded-opentype'),
    url('../fonts/ds_digit-webfont.woff') format('woff'),
    url('../fonts/ds_digit-webfont.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
