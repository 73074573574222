@import '~app/assets/styles/media.less';

.screen {
  width: 100%;
  height: 100%;
}

.picture {
  height: 75%;
  background: url('./images/picture.jpg') no-repeat center;
  background-size: auto 90%;

  @media (--tablet-and-less-media) {
    & {
      background-size: 100% auto;
    }
  }
}

.title {
  height: 10%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.bar-container {
  height: 15%;
  width: 90%;
  margin: 0 auto;

  .bar-text {
    color: #ccc;
    margin-bottom: 0.25rem;
  }
  .bar {
    background-color: #2c2c2c;
    width: 100%;
    height: 0.8rem;

    .bar-filled {
      background-color: #999;
      height: 100%;
    }
  }
}
