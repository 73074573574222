.timer {
  .title {
    font-size: var(--small-size);
  }
  .value {
    font-size: var(--big-size);
    color: red;
    font-family: 'DS-Digital', sans-serif;
  }
}
