@import '~app/assets/styles/media.less';

.frame {
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.pane {
  flex: 1;
  margin: 0.5rem;

  display: flex;
  flex-direction: column;

  @media (--tablet-and-less-media) {
    & {
      margin: 0.5rem 0;
    }
  }
}

.briefing {
  flex-basis: 30%;

  display: flex;
  flex-direction: row;

  .activity {
    @media (--tablet-and-less-media) {
      & {
        display: none;
      }
    }
  }
}

.teams {
  flex-basis: 55%;
  max-height: 60%;

  display: flex;
  flex-direction: row;

  @media (--tablet-and-less-media) {
    & {
      flex-basis: 65%;
      max-height: none;

      flex-direction: column;
    }
  }
}

.outcome {
  flex-basis: 15%;

  display: flex;

  .text {
    flex: 1;
    align-self: center;
    margin: 1rem 0;

    display: flex;
    justify-content: center;
  }
}
