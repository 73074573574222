@import '~app/assets/styles/media.less';

.truncated-width(@value) {
  max-width: @value;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.action-bar {
  width: 100%;
  margin: 0.5rem 0;
  box-sizing: border-box;

  display: flex;
  justify-content: flex-end;

  .button {
    width: 12rem;
    box-sizing: border-box;

    &:not(:first-child) {
      margin-left: 1rem;
    }

    @media (--mobile-media) {
      & {
        width: 50%;
      }
    }
  }
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.servers {
  width: 100%;
  min-height: 90%;
  overflow: auto;
  background: rgba(0, 0, 0, 0.65);
  border-top: 2px solid #666;
  border-bottom: 2px solid #fff;

  overflow-y: scroll;
  scrollbar-width: none; // firefox
  &::-webkit-scrollbar {
    display: none;
  }
}

.server-table {
  height: 100%;
  width: 100%;
  display: table;
  table-layout: fixed;
  box-sizing: border-box;

  .server-passworded,
  .server-passworded-icon {
    width: 2.5%;

    @media (--tablet-and-less-media) {
      & {
        display: none !important;
      }
    }
  }
  .server-passworded-icon {
    background: url('./images/pict_locked.png') no-repeat center center;
    background-size: 1em auto;
  }
  .server-hostname {
    width: 25%;
    .truncated-width(18rem);
  }
  .server-players {
    width: 6%;
  }
  .server-gametype {
    width: 15%;

    @media (--mobile-media) {
      & {
        display: none !important;
      }
    }
  }
  .server-map {
    width: 25%;
    .truncated-width(15rem);

    @media (--mobile-media) {
      & {
        display: none !important;
      }
    }
  }
  .server-address {
    width: 15%;

    @media (--laptop-and-less-media) {
      & {
        display: none !important;
      }
    }
  }
  .server-game {
    width: 10%;

    @media (--tablet-and-less-media) {
      & {
        display: none !important;
      }
    }
  }
  .server-ver {
    width: 5%;

    @media (--mobile-media) {
      & {
        display: none !important;
      }
    }
  }
  .server-location {
    width: 2.5%;
    text-align: center;
  }

  .server-row,
  .server-table-header,
  .server-row-empty {
    display: table-row;

    & > div {
      display: table-cell;
      white-space: nowrap;
      text-align: left;
      padding: 0.15rem 0.3rem;
    }
  }

  .server-row,
  .server-row-empty {
    & > div {
      border-left: 2px solid #fff;
      height: 1.35rem;
    }
    & > div:last-child {
      border-right: 2px solid #fff;
    }
  }

  .server-table-header {
    background-color: #666;
    color: #000;
    font-family: 'Airborne', sans-serif;

    & > div {
      text-align: center;
      height: 1.5rem;
    }
    & > div:first-child {
      border: 2px solid #666 !important;
    }
  }

  .server-row {
    &:target,
    &:target *,
    &:hover,
    &:hover *,
    a:hover,
    &.active {
      background-color: #fff;
      color: #000 !important;
    }
  }

  .server-row-empty > * {
    height: auto !important;
  }
  a {
    text-decoration: none !important;
    color: #fff;
  }
}
