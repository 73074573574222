@picture-size: 3.5em;

.playerCard {
  position: relative;
  display: flex;

  .picture {
    width: @picture-size;
    height: @picture-size;
    border: 1px solid #fff;

    img {
      width: @picture-size;
      height: @picture-size;
    }

    &:global(.-double) {
      width: @picture-size * 2;
      height: @picture-size * 2;

      & > img {
        width: @picture-size * 2;
        height: @picture-size * 2;
      }
    }
  }

  .flag {
    position: absolute;
    top: 0.075rem;
    left: 0.2rem;
    height: 12px;

    img {
      height: 12px;
    }
  }
}
