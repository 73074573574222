@import '~app/assets/styles/media.less';

.container {
  width: 100%;
  margin: 0.75rem;

  display: flex;

  @media (--tablet-and-less-media) {
    & {
      flex-direction: column;
    }
  }
}

.menu {
  flex-basis: 45%;

  @media (--tablet-and-less-media) {
    & {
      margin-bottom: 1rem;
      flex-basis: unset;
    }
  }

  li {
    list-style: none;
  }
}

.heading {
  font-family: 'Bank Gothic Bold', sans-serif;
  text-transform: uppercase;
  font-size: var(--huge-size);
  line-height: 0.8em; // not rem, depends on font size
  text-decoration: none;
  color: #fff;
}

.divider {
  font-family: 'Airborne', sans-serif;
  font-size: var(--small-size);
  letter-spacing: 0.06rem;
  width: 80%;
  padding-top: 0.3rem;
  margin: 0.55rem 0;
  border-top: solid 0.3rem #666;
  color: #888;

  &.finalDivider {
    display: none;

    @media (--tablet-and-less-media) {
      & {
        display: block;
      }
    }
  }
}

.logo-container {
  width: 100%;
  height: 4rem;
  margin-bottom: 0.75rem;
}

.news {
  flex: 1;
}

.article {
  margin-bottom: 1.5rem;

  .text {
    font-size: var(--regular-size);

    p:not(:first-child) {
      margin-top: 0.25rem;
    }
    p:not(:last-child) {
      margin-bottom: 0.25rem;
    }
  }

  footer {
    margin-top: 0.5rem;

    display: flex;
    justify-content: space-between;

    .signature {
      text-align: right;
      color: #999;
    }

    .date {
      color: #999;
      text-align: right;
      font-size: var(--small-size);
    }
  }

  .skeleton-title {
    margin-bottom: 0.75rem;
  }
}
