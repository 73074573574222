@import '~app/assets/styles/media.less';

.container {
  width: 100%;

  display: flex;

  @media (--tablet-and-less-media) {
    & {
      flex-direction: column-reverse;
      height: auto;
    }
  }
}

.game-list-container {
  flex-basis: 70%;
  overflow: auto;
  border: 2px solid #fff;
  box-sizing: border-box;

  @media (--tablet-and-less-media) {
    & {
      flex-basis: 100%;
    }
  }

  .list {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;

    .item {
      flex-basis: calc(20% - (1rem * 4 / 5));
      display: flex;

      @media (--tablet-media) {
        & {
          flex-basis: calc(25% - (1rem * 3 / 4));
        }
      }

      @media (--mobile-media) {
        & {
          flex-basis: calc(50% - (1rem / 2));
        }
      }
    }
  }
}

.search-menu-container {
  flex-basis: 30%;

  @media (--tablet-and-less-media) {
    & {
      flex-basis: unset;
    }
  }

  .search-menu {
    margin-left: 0.5rem;

    @media (--tablet-and-less-media) {
      & {
        margin-bottom: 1rem;
        margin-left: 0;
      }
    }
  }

  .buttons {
    display: flex;

    .search-button {
      margin-right: 0.5rem;
      flex: 2;
    }

    .reset-button {
      margin-left: 0.5rem;
      flex: 1;
    }
  }
}

.load-more {
  width: 100%;
  margin: 1rem 0;
  text-align: center;
  display: flex;
  justify-content: space-around;

  .button {
    width: 15rem;
    text-align: center;
  }
}

.no-games {
  display: flex;
  justify-content: space-around;
  justify-self: stretch;
  width: 100%;

  .text {
  }
}
