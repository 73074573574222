.container {
  flex: 1;

  display: flex;
  align-items: center;
  justify-content: center;
}

.content-box {
  background-color: #000;
  border: 1px solid #fff;
  min-width: 25vw;
  min-height: 5vw;
  padding: 1rem;
  box-sizing: border-box;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
