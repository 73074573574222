@import '~app/assets/styles/media.less';

.round-timer {
  text-align: center;
  margin-top: 0.75rem;

  @media (--tablet-and-less-media) {
    & {
      display: none;
    }
  }
}

.buttons {
  display: flex;

  @media (--mobile-media) {
    & {
      justify-content: space-around;
      flex-direction: column;
    }
  }

  .button-container {
    flex: 1;
    display: flex;

    @media (--mobile-media) {
      & {
        flex: 0;
        margin: 0.25rem 0;
      }
    }

    &:not(:first-child) {
      margin-left: 0.5em;

      @media (--mobile-media) {
        & {
          margin-left: 0;
        }
      }
    }
  }
}
