@import '~app/assets/styles/media.less';

.chapter {
  width: 100%;
  overflow: hidden;
  padding: 0.5rem 0.75rem;
  box-sizing: border-box;

  display: flex;
  flex-direction: column;
  justify-content: stretch;

  @media (--mobile-media) {
    & {
      overflow: auto;
    }
  }
}

.title {
  margin: 0.5rem 0 1rem 0;
  box-sizing: border-box;
}

.content {
  min-height: 0;
  flex: 1;
  box-sizing: border-box;
  display: flex;
}

.search-button {
  width: 15rem;

  @media (--mobile-media) {
    & {
      display: none;
    }
  }
}

.nav-button {
  width: 100%;
  max-width: 13rem;
}
