@import (reference) '~app/assets/styles/variables.less';

@button-height: 1.8rem;

.button {
  font-family: 'Airborne', sans-serif;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  text-align: center;
  width: 100%;
  box-sizing: border-box;
  border: 0.15rem solid #fff;
  background: #000;
  height: @button-height;
  text-decoration: none;
  color: #fff;

  &:after {
    content: attr(data-title);
  }

  &:hover {
    color: @swat-yellow;
    background-color: #000;
    border-color: @swat-yellow;
  }

  &:global(.active),
  &:global(.active):hover,
  &:global(.active):after {
    color: #000 !important;
    border-color: #fff;
    background-color: #fff;
  }

  &:global(.-disabled),
  &:global(.-disabled):after {
    color: #999;
    border-color: #999;
  }
}

.arrow-right:extend(.button all) {
  display: flex;

  &:after {
    margin: 0 0.425rem;
    content: ' ';
    width: 0;
    height: 0;
    border-top: 0.7rem solid transparent;
    border-bottom: 0.7rem solid transparent;
    border-left: 0.7rem solid #fff;
  }

  &:before {
    flex: 1;
    content: attr(data-title);
    width: 0;
    height: @button-height;
    line-height: @button-height;
    border-right: 0.15rem solid #fff;
  }

  &:hover:after {
    color: @swat-yellow;
    background-color: #000;
    border-left-color: @swat-yellow;
  }

  &:hover:before {
    color: @swat-yellow;
    border-color: @swat-yellow;
  }

  // dont change appearance for active arrow buttons
  &:global(.active),
  &:global(.active):hover,
  &:global(.active):before,
  &:global(.active):after {
    color: #000 !important;
  }
  &:global(.active):before {
    border-right-color: #000;
  }
  &:global(.active):after {
    border-left-color: #000;
  }
}

.arrow-left:extend(.arrow-right all) {
  flex-direction: row-reverse;

  &:after {
    border-left: unset;
    border-right: 0.7rem solid #fff;
  }

  &:before {
    border-right: 0;
    border-left: 0.15rem solid #fff;
  }

  &:hover:after {
    color: @swat-yellow;
    border-right-color: @swat-yellow;
  }
}

.exit:extend(.arrow-right all) {
  &:after {
    margin: 0;
    content: '+';
    border: unset;
    height: 1.6rem;
    line-height: 1.7rem;
    width: 1.6rem;
    font-size: 2rem;
    transform: rotate(-45deg);
  }

  &:hover:after {
    color: @swat-yellow;
    background-color: unset;
  }
}

.white:extend(.button all) {
  position: relative;
  background: #fff;
  color: #000;

  &[data-counter]:before {
    position: absolute;
    right: 0;
    color: white;
    width: 1.5rem;
    height: 1.5rem;
    box-sizing: border-box;
    line-height: 1.6rem;
    border-radius: 50%;
    text-align: center;
    background-color: #666;
    content: attr(data-counter);
  }

  &:link,
  &:visited {
    color: #000;
  }

  &:hover,
  &:visited:hover,
  &:global(.active),
  &:global(.active):hover,
  &:global(.active):after,
  &:global(.active):hover:after {
    color: #000;
    border-color: @swat-yellow;
    background-color: @swat-yellow;
  }
}

.submit:extend(.white all) {
  width: 3rem;
  height: 3rem;

  &:global(.-small) {
    width: 2rem;
    height: 2rem;
  }

  &:after {
    height: 1.8rem;
    width: 0.9rem;
    transform: rotate(45deg);
    border-right: 0.5rem solid #000;
    border-bottom: 0.5rem solid #000;
    margin: 0 0 0.25rem;
  }

  &:global(.-small):after {
    height: 1.25rem;
    width: 0.6rem;
    border-right-width: 0.25rem;
    border-bottom-width: 0.25rem;
  }

  &:global(.active):after,
  &:global(.active):hover:after {
    border-color: #000;
  }
}

.cancel:extend(.white all) {
  width: 3rem;
  height: 3rem;
  padding: 0;

  &:after {
    margin: 0;
    content: '\00D7';
    border: unset;
    height: 3rem;
    line-height: 3.5rem;
    width: 3rem;
    font-size: 4.25rem;
  }
}
