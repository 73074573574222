@import (reference) '~app/assets/styles/variables.less';

@keyframes skeleton-loading {
  to {
    background-position-x: -20%;
  }
}

.card {
  flex: 1 0 auto;
  aspect-ratio: 1 / 1.125;
  text-decoration: none;

  display: flex;
}

.card-frame {
  flex: 1;
  background-color: #fff;
  padding: 0.35rem 0.35rem 1.5rem 0.35rem;

  display: flex;

  &:not(.skeleton):hover {
    background-color: @swat-yellow;
  }
}

.picture {
  flex: 1;
  background-color: #ccc;
  background-size: cover;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.skeleton-picture:extend(.picture) {
  background: linear-gradient(
      100deg,
      rgba(255, 255, 255, 0) 40%,
      rgba(255, 255, 255, 0.5) 50%,
      rgba(255, 255, 255, 0) 60%
    )
    #ededed;
  background-size: 200% 100%;
  background-position-x: 180%;
  animation: skeleton-loading 1s ease-in-out infinite;
}

.upper-info {
  flex-basis: 15%;
  font-size: var(--small-size);
  background: rgba(0, 0, 0, 0.25);
  color: #fff;

  .date-finished {
    text-align: right;
    padding: 0.15rem 0.25rem;
  }
}

.lower-info {
  flex-basis: 15%;
  font-size: var(--small-size);
  background: rgba(0, 0, 0, 0.5);
  color: #fff;

  display: flex;
  justify-content: space-between;

  .score {
    text-align: left;
    padding: 0.15rem 0.25rem;
  }
  .gametype {
    text-align: right;
    padding: 0.15rem 0.25rem;
  }
}
