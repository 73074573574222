@import '~app/assets/styles/media.less';

.container {
  flex: 1;

  display: flex;
  flex-direction: column;

  .title {
    margin: 0.5rem 0 1rem 0;
    box-sizing: border-box;
  }

  .content-box {
    width: 100%;
    flex-basis: 80%;

    @media (--mobile-media) {
      & {
        flex-basis: 100%;
      }
    }
  }
}

.content {
  flex: 1;
  margin: 0.5rem;

  display: flex;
  justify-content: space-between;

  @media (--tablet-media) {
    & {
      flex-direction: column-reverse;
    }
  }

  .tutorial {
    flex-basis: 50%;
    margin-right: 3rem;

    display: flex;

    @media (--tablet-and-less-media) {
      & {
        margin-right: 0;
      }
    }

    @media (--mobile-media) {
      & {
        display: none;
      }
    }
  }

  .guide {
    flex-basis: 50%;

    display: flex;

    @media (--mobile-media) {
      & {
        flex-basis: unset;
      }
    }
  }

  @media (--mobile-media) {
    .tutorial {
      display: none;
    }
    .guide {
      flex-basis: unset;
    }
  }
}
