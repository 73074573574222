@keyframes loading {
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
}

.skeleton {
  background: linear-gradient(90deg, #2c2c2c 25%, #1c1c1c 50%, #2c2c2c 75%);
  background-size: 200% 100%;
  animation: loading 1.5s infinite;
  border-radius: 4px;
}

.title:extend(.skeleton) {
  height: 1.5rem;
}

.text:extend(.skeleton) {
  height: 1.25rem;
}

.paragraph:extend(.skeleton) {
  display: grid;
  gap: 0.25rem;
  grid-template-rows: repeat(3, 1rem);
}

.image:extend(.skeleton) {
  width: 100%;
  height: 0;
  padding-bottom: 100%;
  background-color: #1c1c1c;
}
