@swat-yellow: #ffff00;
@swat-blue: lighten(#0000ff, 10%);
@swat-red: #ff0000;
@swat-green: #00ff00;

@mobile-width: 768px;
@tablet-width: 1024px;
@laptop-width: 1366px;

:root {
  /* font sizes */
  --tiny-size: clamp(0.7rem, 1vw, 3rem);
  --small-size: clamp(0.8rem, 1.05vw, 3rem);
  --regular-size: clamp(1rem, 1.1vw, 3rem);
  --big-size: clamp(1.5rem, 3.5vw, 5rem);
  --huge-size: clamp(2.25rem, 4.25vw, 5rem);

  /* media breakpoints */
  --mobile-width: @mobile-width;
  --tablet-width: @tablet-width;
  --laptop-width: @laptop-width;
}
