@import '~app/assets/styles/media.less';

.container {
  flex: 1;

  display: flex;
  flex-direction: column;
}

.server-list-container {
  width: 100%;
  min-height: 90%;
  background: rgba(0, 0, 0, 0.65);
  position: relative;
}

// FIXME: revise columns, add header titles
.server-table {
  height: 100%;
  width: 100%;
  display: table;
  table-layout: fixed;
  box-sizing: border-box;

  .server-passworded {
    width: 2.5%;

    @media (--tablet-and-less-media) {
      & {
        display: none !important;
      }
    }
  }
  .server-hostname {
    width: 25%;
  }
  .server-players {
    width: 6%;
  }
  .server-gametype {
    width: 15%;

    @media (--mobile-media) {
      & {
        display: none !important;
      }
    }
  }
  .server-map {
    width: 25%;

    @media (--mobile-media) {
      & {
        display: none !important;
      }
    }
  }
  .server-address {
    width: 15%;

    @media (--laptop-and-less-media) {
      & {
        display: none !important;
      }
    }
  }
  .server-game {
    width: 10%;

    @media (--tablet-and-less-media) {
      & {
        display: none !important;
      }
    }
  }
  .server-ver {
    width: 10%;

    @media (--mobile-media) {
      & {
        display: none !important;
      }
    }
  }

  .server-table-header,
  .server-table-body {
    display: table-row;

    & > div {
      display: table-cell;
      white-space: nowrap;
      text-align: left;
      padding: 0.15rem 0.3rem;
    }
  }

  .server-table-body {
    & > div {
      border-left: 2px solid #fff;
      border-bottom: 2px solid #fff;
      height: 1.35rem;
    }
    & > div:last-child {
      border-right: 2px solid #fff;
    }
  }

  .server-table-header {
    background-color: #666;
    color: #000;
    font-family: 'Airborne', sans-serif;

    & > div {
      text-align: center;
      height: 1.5rem;
    }
    & > div:first-child {
      border: 2px solid #666 !important;
    }
  }

  .server-table-body > div {
    height: auto;
  }
}

.direct-form-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1000;
  background: rgba(0, 0, 0, 0.8);

  display: flex;
  justify-content: center;
  align-items: center;
}

.direct-form-container {
  background-color: #000;
  border: 2px solid #fff;
  min-width: 35vw;
  min-height: 15vw;
  padding: 2rem;
  box-sizing: border-box;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (--tablet-and-less-media) {
    & {
      width: 80%;
    }
  }
}

.direct-form {
  flex: 1 0 auto;
  width: 80%;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .fields {
    justify-content: center;

    display: flex;
    flex-direction: column;
  }

  .buttons {
    margin-top: 1.5rem;

    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.action-bar {
  width: 100%;
  margin: 1rem 0;
  box-sizing: border-box;

  display: flex;
  justify-content: center;

  .button {
    width: 7rem;
    box-sizing: border-box;

    &:not(:first-child) {
      margin-left: 1rem;
    }
  }
}
