@import (reference) '~app/assets/styles/variables.less';
@import '~app/assets/styles/media.less';

.frame {
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (--mobile-media) {
    & {
      justify-content: flex-start;
    }
  }
}

.pane {
  margin: 0.5rem;

  display: flex;
  flex-direction: column;

  @media (--tablet-and-less-media) {
    & {
      margin: 0.5rem 0;
    }
  }
}

.briefing {
  flex-basis: 35%;

  display: flex;
  flex-direction: row;

  .activity {
    flex: 1;

    @media (--tablet-and-less-media) {
      & {
        display: none;
      }
    }
  }

  .rules {
    flex: 1;
  }
}

.teams {
  flex-basis: 50%;
  max-height: 60%;

  display: flex;
  flex-direction: row;

  @media (--tablet-and-less-media) {
    & {
      max-height: none;
      flex-basis: 65%;

      flex-direction: column;
    }
  }
}
