@import (reference) '~app/assets/styles/variables.less';
@import '~app/assets/styles/media.less';

.team {
  overflow: hidden;

  @media (--tablet-and-less-media) {
    & {
      overflow: unset;
    }
  }

  .player {
    display: flex;
    align-items: center;
    justify-content: left;

    @media (--tablet-and-less-media) {
      & {
        justify-content: space-between;
      }
    }

    .ping {
      width: 5%;
      text-align: center;
      margin-right: 0.1rem;

      & > img {
        height: 0.75rem;
      }

      @media (--mobile-media) {
        & {
          display: none;
        }
      }
    }

    .name {
      flex-basis: 65%;
      text-align: left;
    }

    .status {
      flex-basis: 30%;
      text-align: left;

      @media (--tablet-and-less-media) {
        & {
          flex: 1;
          text-align: right;
        }
      }

      &.injured {
        color: @swat-yellow;
      }
      &.incapacitated {
        color: @swat-red;
      }
      &.healthy {
        color: @swat-green;
      }
      &.notReady {
        color: #999;
      }
    }

    .skeleton {
      width: 100%;
      margin: 0.1rem 0;
    }
  }
}
