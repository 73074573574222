@import (reference) '~app/assets/styles/variables.less';
@import '~app/assets/styles/media.less';

.frame {
  flex: 1;

  display: flex;
  justify-content: space-between;

  @media (--tablet-and-less-media) {
    & {
      flex-direction: column;
      justify-content: flex-start;
    }
  }
}

.pane {
  flex: 1;
  margin: 0.5rem;

  display: flex;
  flex-direction: column;

  @media (--tablet-and-less-media) {
    & {
      flex: 0;
      margin: 0.5rem 0;
    }
  }

  & > div:not(:first-child) {
    margin-top: 1rem;
  }
}

.objectives {
  flex-basis: 40%;
}

.team {
  flex-basis: 40%;
}

.totals {
  @media (--tablet-and-less-media) {
    & {
      flex-basis: 100%;
    }
  }
}

.procedures {
  flex-basis: 40%;
}

.procedure-table {
  width: 100%;
  display: table;

  .procedure-row,
  .procedure-total-row {
    display: table-row;
    width: 100%;

    .name {
      width: 50%;
      display: table-cell;
      text-align: left;
    }
    .status {
      width: 25%;
      display: table-cell;
      text-align: center;
    }
    .score {
      width: 25%;
      display: table-cell;
      text-align: center;

      &:global(.-positive) {
        color: @swat-green;
      }
      &:global(.-negative) {
        color: @swat-red;
      }
    }

    .skeleton {
      margin: 0.25rem 0;
    }
  }

  .procedure-total-row > div {
    height: 1.75rem;
    vertical-align: bottom;
    font-weight: bold;
  }
}

.ranking {
  text-align: right;
  font-family: 'Bank Gothic Bold', sans-serif;
  text-transform: uppercase;
  font-size: var(--big-size);
  line-height: 0.8em; // not rem; relative to the container's font size
  flex: 1;

  display: flex;
  align-items: flex-end;
  justify-content: flex-end;

  .score {
    .perfect {
      color: @swat-green;
    }
    .imperfect {
      color: @swat-red;
    }
  }
}
