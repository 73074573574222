@import '~app/assets/styles/media.less';

.container {
  flex: 1 0 auto;
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .errors {
    width: 100%;
    text-align: center;
    color: red;
    margin-bottom: 0.75rem;

    .placeholder {
      &:after {
        content: '\00a0';
      }
    }
  }

  .fields {
    justify-content: center;

    display: flex;

    .address {
      flex-basis: 50%;
      margin-right: 0.75rem;

      @media (--tablet-and-less-media) {
        & {
          flex-basis: 70%;
        }
      }
    }

    .port {
      flex-basis: 30%;
      margin-left: 0.75rem;
    }
  }

  .buttons {
    flex: 1;
    margin-top: 1.5rem;

    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
