@import (reference) '~app/assets/styles/variables.less';
@import '~app/assets/styles/media.less';

@swat-cursor: '../cursors/black.cur';

html,
body {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
}

sub,
sup {
  font-size: var(--tiny-size);
}

button,
input {
  border: 0;
  background: none;
  box-sizing: border-box;
}

body,
button,
input[type='submit'],
input[type='button'] {
  cursor: url(@swat-cursor), auto;
}

body {
  font-family: 'Play', sans-serif;
  text-align: left;
  color: #fff;
  overflow: hidden;
  background: #000;
  font-size: var(--regular-size);

  @media (--tablet-and-less-media) {
    & {
      overflow: auto;
    }
  }
}

ul {
  padding: 0;
}

p {
  padding: 0;
  margin: 0;
}

a {
  cursor: url(@swat-cursor), auto;
  outline: none;

  :global(&.unstyled) {
    text-decoration: none;
    color: inherit;
  }
}

a:link,
a:visited {
  color: #fff;
}

a:hover,
a:visited:hover {
  color: @swat-yellow;
}

:global(#root) {
  width: 100%;
  height: 100%;
}

:global {
  .w100 {
    width: 100%;
  }
  .w75 {
    width: 75%;
  }
  .w50 {
    width: 50%;
  }
  .w33 {
    width: 33%;
  }
  .w25 {
    width: 25%;
  }
  .w15 {
    width: 15%;
  }
  .w10 {
    width: 10%;
  }
  .w5 {
    width: 5%;
  }
}
