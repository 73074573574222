@import '~app/assets/styles/media.less';

.black-bar() {
  flex-basis: 12.5%;
  width: 100%;
  background: #000;
  z-index: 1000;

  @media (--mobile-media) {
    & {
      flex-basis: auto;
    }
  }
}

.site-container {
  width: 100%;
  height: 100%;

  @media (--tablet-and-less-media) {
    & {
      overflow-y: auto;
    }
  }
}

.site {
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #080808;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (--tablet-and-less-media) {
    & {
      height: unset;
      min-height: 100%;
    }
  }
}

.header {
  .black-bar();
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (--mobile-media) {
    & {
      flex-wrap: wrap;
    }
  }

  .left,
  .right {
    flex-grow: 1;
    flex-basis: 25%;
    box-sizing: border-box;

    @media (--mobile-media) {
      & {
        flex-basis: 50%;
      }
    }
  }

  .middle {
    flex-grow: 2;
    display: flex;
    justify-content: center;
    margin: 0.25rem 0;

    @media (--mobile-media) {
      & {
        order: 1;
      }
    }
  }

  .left {
    align-self: flex-end;
    padding: 0.5rem 0.75rem;
    display: flex;
    justify-content: flex-start;
  }

  .right {
    align-self: flex-end;
    padding: 0.5rem 0.75rem;
    display: flex;
    justify-content: flex-end;
  }
}

.content {
  width: 100%;
  flex: 1 0 75%;
  background-size: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  align-items: stretch;

  @media (--tablet-and-less-media) {
    & {
      flex-basis: 100%;
    }
  }
}

.footer {
  .black-bar();
  display: flex;
  justify-content: space-between;

  @media (--mobile-media) {
    & {
      flex-direction: column-reverse;
    }
  }

  @media (--tablet-and-less-media) {
    & {
      flex-wrap: wrap;
    }
  }

  .left {
    text-align: left;
    flex-basis: 40%;
    display: flex;
    flex-direction: column;
    padding-left: 0.75rem;
    box-sizing: border-box;

    &-upper {
      width: 100%;
      align-self: flex-start;
      flex-grow: 1;
      margin-top: 0.5rem;
    }

    &-lower {
      width: 100%;
      align-self: flex-end;
      margin-bottom: 0.25rem;
      font-size: var(--small-size);

      @media (--tablet-and-less-media) {
        & {
          margin-top: 0.5rem;
        }
      }
    }
  }

  .middle {
    flex-basis: 20%;
    display: flex;
    align-items: center;
    justify-content: space-around;

    @media (--tablet-media) {
      & {
        flex-basis: 100%;
        order: 2;
      }
    }
  }

  .right {
    text-align: right;
    flex-basis: 40%;
    display: flex;
    flex-direction: column;
    padding-right: 0.75rem;
    box-sizing: border-box;

    &-upper {
      width: 100%;
      flex: 1;
      margin-top: 0.5rem;
    }

    &-copy {
      width: 100%;
      box-sizing: border-box;
      font-size: var(--small-size);
      margin-bottom: 0.25rem;

      @media (--tablet-and-less-media) {
        & {
          margin-top: 0.5rem;
        }
      }
    }
  }

  .left,
  .middle {
    @media (--mobile-media) {
      & {
        display: none;
      }
    }
  }

  .right {
    @media (--mobile-media) {
      & {
        width: 100%;
        padding: 0 0.75rem;
      }
    }
  }

  .left,
  .right {
    @media (--tablet-media) {
      & {
        flex-basis: 50%;
      }
    }
  }
}
