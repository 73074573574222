.inputStyles() {
  width: 100%;
  line-height: 1.25rem;
  padding: 0.2rem;
  border: 0;
  border-radius: 0;
  text-overflow: '';
  text-indent: 0.01px;
  appearance: none;
  box-sizing: border-box;
}

.fieldLabel {
  margin-bottom: 0.35rem;
}

.fieldRow {
  margin-bottom: 0.75rem;
  width: 100%;
}

.field-input-container {
  width: 100%;

  & > input {
    background-color: #fff;
    .inputStyles();
  }
}

.field-checkbox-select-container {
  display: flex;

  .field-checkbox-container {
    margin-right: 0.75rem;
  }
}

.field-checkbox-container {
  input[type='checkbox'] {
    display: none;

    &:checked + span {
      &:after {
        content: ' ';
        height: 1.25rem;
        width: 0.5rem;
        transform: rotate(45deg);
        border-right: 0.2rem solid #fff;
        border-bottom: 0.2rem solid #fff;
      }
    }
  }

  span {
    width: 1.7rem;
    height: 1.7rem;
    background-color: #000;
    border: 0.1rem solid #fff;

    display: flex;
    justify-content: center;
  }
}

.field-select-container {
  width: 100%;
  height: 1.9rem;
  background: url('./images/arrow_drop_down.svg') no-repeat;
  background-position: right center;
  background-size: 2.5rem auto;
  background-color: #fff;

  & > select {
    .inputStyles();
    background: transparent;
    line-height: 1.5rem;
  }

  &[data-disabled='true'] {
    background-image: none;
  }
}

.field-datetime-container {
  display: flex;
  width: 100%;

  .fieldDatetimeYear {
    width: 27.5%;
  }
  .fieldDatetimeMonth {
    width: 45%;
    margin-left: 0.5em;
  }
  .fieldDatetimeDay {
    width: 27.5%;
    margin-left: 0.5em;
  }
}
