@import '~app/assets/styles/media.less';

.introduction {
  margin-bottom: 1.25rem;
}

.steps {
  margin-left: 1.25rem;

  li {
    list-style-type: square;
    margin-bottom: 1rem;

    &:global(.active) {
      color: @swat-yellow;
    }
  }
}

.join-game-caption {
  display: inline-block;
  font-family: 'Bank Gothic Bold', sans-serif;
  text-transform: uppercase;
  font-size: 1.5rem;
}

.direct-button-container {
  display: inline-block;
  margin: 0 0.1rem;

  .direct-button {
    padding: 0 1rem;
  }
}

.join-button-container {
  width: 2rem;
  height: 2rem;
  display: inline-block;
  margin: 0 0.1rem;
}

.server-address {
  margin-top: 0.25rem;
  width: 50%;

  @media (--mobile-media) {
    & {
      width: 75%;
    }
  }
}
