.content-box {
  height: 100%;
  background: rgba(0, 0, 0, 0.65);
  box-sizing: border-box;

  display: flex;
  flex-direction: column;

  &:global(.-scrollable) {
    overflow: auto;
    height: 100%;
  }

  &:global(.-bordered) {
    border: 0.1rem solid white;
  }

  & > header {
    color: #000;
    background-color: #fff;
    padding: 0.15rem 0.5rem;
    font-weight: bold;
  }

  & > article {
    flex: 1;
    padding: 0.5rem;
    line-height: 1.15em;
    box-sizing: border-box;

    display: flex;
    flex-direction: column;
  }
}
