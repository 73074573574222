.yes-no-button-bar {
  flex: 1;

  display: flex;
  justify-content: space-between;

  .yes,
  .no {
    flex: 1;

    display: flex;
    align-items: center;
    justify-content: center;
  }
}
