@import '~app/assets/styles/media.less';

.container {
  flex: 1 0 auto;
  width: 100%;

  .fields {
    display: flex;

    .pane {
      flex: 1;

      &:first-child {
        margin-right: 1.25rem;
        @media (--tablet-and-less-media) {
          & {
            margin-right: 0;
          }
        }
      }

      &:not(:first-child) {
        margin-left: 1.25rem;
      }

      @media (--tablet-and-less-media) {
        &:not(:first-child) {
          display: none;
        }
      }
    }
  }

  .buttons {
    flex: 1;
    margin-top: 1.5rem;

    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
