.paneTitle {
  font-family: 'Bank Gothic Bold', sans-serif;
  text-transform: uppercase;
  font-size: var(--huge-size);
  line-height: 0.8em; // not rem
  text-decoration: none;
  color: #fff;
}

.paneContent {
  padding: 0.5rem;
}
