@import '~app/assets/styles/media.less';

.modal-overlay {
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 2000;
  background: rgba(0, 0, 0, 0.8);

  display: flex;
  justify-content: center;
  align-items: center;
}

.modal {
  background-color: #000;
  border: 2px solid #fff;
  min-width: 45vw;
  min-height: 15vw;
  padding: 2rem;
  box-sizing: border-box;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  @media (--tablet-media) {
    & {
      flex-basis: 70%;
    }
  }

  @media (--mobile-media) {
    & {
      padding: 1rem;
      flex-basis: 90%;
    }
  }
}
