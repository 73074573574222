.container {
  flex: 1 0 auto;
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .input {
    flex: 1;
  }

  .buttons {
    flex: 1;
    margin-top: 1rem;

    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
