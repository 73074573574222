.container {
  height: 100%;
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
}

.error {
  display: flex;
  flex-direction: column;
  align-items: center;

  .message {
    margin-bottom: 2.5rem;
  }

  .button {
    min-width: 15rem;
  }
}
