@import '~app/assets/styles/media.less';

.round-info {
  text-align: center;
  margin-top: 0.5rem;
  font-size: var(--small-size);
}

.game-info {
  @media (--mobile-media) {
    & {
      display: none;
    }
  }
}

.lower-button-bar {
  display: flex;
  justify-content: flex-end;

  @media (--mobile-media) {
    & {
      justify-content: space-around;
      flex-direction: column;
    }
  }

  .button-container {
    flex: 1;
    display: flex;

    @media (--mobile-media) {
      & {
        flex: 0;
        margin: 0.25rem 0;
      }
    }

    &:not(:first-child) {
      margin-left: 0.5rem;

      @media (--mobile-media) {
        & {
          margin-left: 0;
        }
      }
    }
  }
}

.round-timer {
  text-align: center;
  margin-top: 0.75rem;

  @media (--tablet-and-less-media) {
    & {
      display: none;
    }
  }
}
