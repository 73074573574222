@import (reference) '~app/assets/styles/variables.less';
@import '~app/assets/styles/media.less';

.table-icon(@url) {
  background: url(@url) center center no-repeat;
  background-size: auto 1.15rem;
}

.team {
  flex: 1;
  border-bottom: 2px solid #ffffff;
  overflow: auto;

  @media (--mobile-media) {
    & {
      overflow: unset;
    }
  }
}

.team-stats {
  display: flex;
  margin-bottom: 0.25rem;

  .team-name {
    align-self: flex-start;
  }
  .team-victories {
    align-self: flex-start;
    margin-left: 0.8rem;
  }
}

.team-table {
  width: 100%;
  //height: 100%;
  display: table;
  background: rgba(0, 0, 0, 0.65);
  //margin-top: 0.25rem;
  flex: 1;
  min-height: 10rem;

  scrollbar-width: none; // firefox
  &::-webkit-scrollbar {
    display: none;
  }

  .player-name {
    padding-left: 0.35rem;
    text-align: left;
    width: 40%;

    &:global(.-vip) {
      color: @swat-green !important;
    }
  }

  .player-score,
  .player-ping {
    width: 8%;
    text-align: center;

    & > img {
      height: 0.85rem;
      display: block;
      margin: auto;
    }
  }

  .player-kills,
  .player-deaths,
  .player-arrests,
  .player-arrested,
  .player-obj {
    width: 8%;
    text-align: center;

    @media (--mobile-media) {
      & {
        display: none !important;
      }
    }
  }

  .team-row,
  .team-row-empty,
  .team-table-header {
    display: table-row;

    & > div {
      display: table-cell;
      vertical-align: middle;
    }
  }

  .team-row,
  .team-row-empty {
    & > div:first-child {
      border-left: 2px solid #ffffff;
    }

    & > div {
      height: 1.25rem;
      border-right: 2px solid #ffffff;
    }
  }

  .team-table-header {
    color: #000;
    background-color: #666;
    font-family: 'Airborne', sans-serif;

    & > div {
      height: 1.75rem;
    }

    .player-score {
      .table-icon('./images/score.png');
    }
    .player-kills {
      .table-icon('./images/kills.png');
    }
    .player-deaths {
      .table-icon('./images/deaths.png');
    }
    .player-arrests {
      .table-icon('./images/arrests.png');
    }
    .player-arrested {
      .table-icon('./images/arrested.png');
    }
    .player-obj {
      .table-icon('./images/special.png');
    }
    .player-ping {
      .table-icon('./images/ping.png');
    }
  }

  .team-row {
    &:target,
    &:target div,
    &:hover,
    &:hover div,
    a:hover {
      background-color: #fff;
      color: #000 !important;
    }
  }

  .team-row-empty {
    height: auto !important;

    & > div {
      height: auto !important;
    }
  }
}
